.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"
}

.MuiInput-root::before {
  border: none !important;
}

/* .MuiListItemButton-root:hover{
  background-color: #03520d51 !important;
} */


.list-items:hover {
  background: #ffffff14 !important;
}

a {
  text-decoration: none;
}

.orderLink {
  color: inherit;
}

.orderLink:hover {
  color: #5048e5;
  text-decoration: underline;
  font-weight: bold;
}

.card_mi_ui {
  box-shadow: 1px 1px 1px #64748b0f, 2px 3px 2px #64748b1a !important;
}

.underline:hover {
  text-decoration: underline;
}

::selection {
  background-color: #5048e5;
  color: #fff;
}

input {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(167, 167, 167);
}

input:focus-within {
  border: 1px solid #5048e5;
}

select {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid rgb(167, 167, 167);
}

select:focus-within {
  border: 1px solid #5048e5;
}

option {
  font-size: 1.1rem;
}

.login-main {
  background: url("/public/login.webp") center center/cover no-repeat;
  height: 100vh;
  opacity: 0.7;
  width: 100vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.not_found {
  background: url("/public/404.jpg") center center/cover no-repeat;
  height: 100vh;
  width: 100vw;
}

.chip:hover + .logout_btn {
  display: block !important;
}

.logout_btn {
  display: none !important;
  font-size: 0.9rem !important;
  color: #000;
  border-radius: 5px !important;
  margin-top: 1rem !important;
  position: absolute !important;
  top: 2.6rem !important;
  right: 2rem;
  z-index: 1000 !important;
  background: #dedcff !important;
  box-shadow: 0px 0px 0px 0px black;
  font-weight: bold;
}

.orderDetailsActions button{
  border-radius: 10px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
}

.logout_btn:hover{
  display: block !important;
}

.logout_btn::before{
  content: "" !important;
  display: block !important;
  background: #dedcff !important;
  position: absolute;
  top: -0.1rem;
  width: 2rem;
  height: 1rem;
  left: 3.5px;
  z-index: -1;
  transform: rotateZ(225deg);
  /* box-shadow: 0px 0px 5px 0px black; */
}